.payment-period {
  font-size: 14px;
  font-weight: bold;
  color: rgba(0,0,0,0.85);
}

.div-payment-description {
  flex-direction: row;
  display: flex;
  margin-top: 8px;
}

.box-payment-description {
  border: 1px solid #1890FF;
  background-color: #E6F7FF;
  border-radius: 2px;
  flex-direction: row;
  display: flex;
  align-items: center;
  padding: 4px;
}

.box-icon-info {
  border-radius: 50px;
  background-color: #1890FF;
  display: inline-flex;
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

.icon-info {
  color: #fff;
  font-size: 18px;
  width: 18px;
  height: 18px;
}

.loading {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 9999;
  top: 0px;
  right: 0px;
  background-color: #fff;
  opacity: 0.9;
}

.price-per-hour-container {
  padding: 16px;
  margin: 26px 16px 0px 64px;
  background-color: #f3f2f380;
  border: 1px solid #cececd;
}

.price-per-hour-info {
  margin-left: 12px;
}