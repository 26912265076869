.container {
  position: relative;
  background-color: #fff;
  border-radius: 4px;
  padding: 19px 24px;
  margin-bottom: 15px;
  overflow-x: auto;
}

.containerHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f3f2f3;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.title {
  font-weight: bold;
  font-size: 11px;
  line-height: 13px;
  color: #747277;
  margin-right: 12px;
}

.loading {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 9999;
  top: 0px;
  right: 0px;
  background-color: #fff;
  opacity: 0.9;
}

.mousePointer {
  cursor: pointer;
}
