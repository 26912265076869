.container {
  padding: 0px 15px;
}

.filter {
  border-radius: 5px;
  background-color: white;
  padding: 16px;
  margin-bottom: 15px;
  margin-top: 15px;
  box-shadow: 0px 1px 2px -1px;
  -moz-box-shadow: 0px 1px 2px -1px;
  -webkit-box-shadow: 0px 1px 2px -1px;
}

.table {
  background-color: white;
  padding: 0px 5px;
}

.footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.firstInput {
  border-radius: 4px 0px 0px 4px !important;
}

.buttonSearch {
  width: 10%;
  border-radius: 0px !important;
}

.buttonNew {
  width: 10%;
  border-radius: 0px 4px 4px 0px !important;
}

.success {
  color: #37841d !important;
  border-color: #37841d !important;
}

.spaceBetweenIcons {
  margin-left: 5px;
}

.titleForSelectModalEdit {
  line-height: 25px;
  width: 100%;
  color: #747277;
}

.titleNewCompanyEdt {
  color: #fc385c;
  font-weight: bold;
  text-transform: uppercase;
}
