.balanceContainerValues {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.balanceColumn {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.balanceText {
  font-weight: 400;
  font-size: 10px;
  color: #747277;
}

.balanceValue {
  font-weight: 500;
  font-size: 12px;
}

.balanceContainerProgress {
  display: flex;
  position: relative;
  margin-top: 6px;
}

.balanceProgressBarLeft {
  position: absolute;
  height: 3px;
  border-radius: 5px;
  background-color: #1890ff;
}

.balanceProgressBarRight {
  flex: 1;
  height: 3px;
  border-radius: 5px;
  background-color: #c1c1c1;
}
