.filter-modal-batch{
    border-radius: 5px;
    background-color:  white;
    padding: 16px;
    margin-bottom: 15px;
    margin-top: 15px;
    box-shadow: 0px 1px 2px -1px;
   -moz-box-shadow: 0px 1px 2px -1px;
   -webkit-box-shadow: 0px 1px 2px -1px;
   display: flex;
   margin-left: 20px;
   margin-right: 20px;
  }

.update-info {
    display: flex;
    width: 250px;
    flex-direction: column;
}

.margin-container-style {
    border: 1px solid #CECECD;
    padding: 15px;
    margin-bottom: 5px;
    margin-top: 30px;
    width: 70%;
  }

  .keep-btn-change-btn { 
    display: flex;
  }
  
.container-contents {
    display: flex;
    justify-content: space-between;
    align-items: center;
}