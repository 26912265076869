.container {
  padding: 15px;
}

.content {
  background-color: white;
  height: 100vh;
  border-radius: 5px;
  padding: 20px;
}

.filter {
  border-radius: 5px;
  background-color: white;
  padding: 16px;
  margin-bottom: 15px;
  margin-top: 15px;
  box-shadow: 0px 1px 2px -1px;
  -moz-box-shadow: 0px 1px 2px -1px;
  -webkit-box-shadow: 0px 1px 2px -1px;
}

.table {
  background-color: white;
  padding: 0px 5px;
}

.footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.firstInput {
  border-radius: 4px 0px 0px 4px !important;
}

.buttonSearch {
  width: 10%;
  border-radius: 0px !important;
}

.buttonNew {
  width: 10%;
  border-radius: 0px 4px 4px 0px !important;
}

.ant-upload-list-item {
  margin-top: 4px !important;
}

.ant-upload {
  width: 100% !important;
}

.btn-upload {
  width: 100%;
  margin-top: 3px;
  height: 66px !important;
  overflow: hidden;
}

.radioVertical {
  display: block !important;
  margin-top: 5px !important;
  /* line-height: 30px !important; */
}
