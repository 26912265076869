.container {
  padding: 0px 15px;
}

.filter {
  border-radius: 5px;
  background-color: white;
  padding: 16px;
  margin-bottom: 15px;
  margin-top: 15px;
  box-shadow: 0px 1px 2px -1px;
  -moz-box-shadow: 0px 1px 2px -1px;
  -webkit-box-shadow: 0px 1px 2px -1px;
}

.table {
  background-color: white;
  padding: 0px 5px;
}

.footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.firstInput {
  border-radius: 4px 0px 0px 4px !important;
  /* max-width: 300px; */
}

.buttonSearch {
  width: 10%;
  border-radius: 0px !important;
}

.buttonNew {
  width: 10%;
  border-radius: 0px 4px 4px 0px !important;
}

.success {
  color: #37841d !important;
  border-color: #37841d !important;
}

.spaceBetweenIcons {
  margin-left: 5px;
}

#form_edit_station .ant-form-item-label {
  font-weight: bold !important;
}

.viewStatus {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.stationsDisplayed {
  font-weight: 700;
}

.Charging {
  height: 10px;
  width: 11px;
  border: 2px solid #fff;
  border-radius: 50px;
  background-color: #7a84bf;
  margin-right: 10px;
}

.stationName {
  font-size: 14px;
  display: flex;
  flex-direction: row;
}

.stationId {
  color: #595959;
  font-size: 12px;
  opacity: 0.65;
  font-weight: 400;
}

.id {
  color: #595959;
  font-size: 14px;
  font-weight: 700;
}

.left {
  width: 9px;
  height: 9px;
  border: 2px solid #747277;
  border-top-width: 0px;
  border-left-width: 0px;
  border-radius: 1px;
  transform: rotate(135deg);
  margin-top: 5px;
}

.displayTop {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 40px;
  border: none;
  background-color: inherit;
  cursor: pointer;
}

.displayTop:hover {
  background-color: inherit;
}

.displayInfos {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 40px;
  justify-content: space-between;
  flex-wrap: wrap;
}
.displayChargerHistoryInfos {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 40px;
  justify-content: left;

  .displayDiv {
    padding-left: 20px;
  }
  .displayDiv:first-child {
    padding-left: 0;
  }

  .subtitle {
    input {
      width: 280px;
    }
  }

  .attachButton {
    margin: auto;
  }
}

.displayDiv {
}

.backTitle {
  font-size: 14px;
  line-height: 17px;
  text-decoration: underline;
  color: #000000;
  opacity: 0.65;
  padding-left: 4px;
}

.title {
  color: #434341;
  opacity: 0.65;
  font-size: 12px;
  line-height: 14px;
}

.subtitle {
  font-size: 18px;
  color: #434341;
  line-height: 21px;
  margin-top: 4px;
  font-weight: 700;
  display: flex;
  align-items: center;
}

.card-container > .ant-tabs-card > .ant-tabs-content {
  margin-top: -16px;
}

.card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
  background: #fff;
  padding: 16px;
}

.card-container > .ant-tabs-card > .ant-tabs-bar {
  border-color: #fff;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  border-color: transparent;
  background: transparent;
}

.card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
  border-color: #fff;
  background: #fff;
}
