.button-div {
  flex: 1;
  flex-flow: flex-end;
  text-align: end;
  margin-top: 20px;
}

.button {
  padding: 6px 15px;
  background: #FF375C;
  color: #ffff;
  border: 0px;
  border-radius: 50px;
}
