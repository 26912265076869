.stations-modal-batch { 
  display: block;
  height: 40px;
}

.stations-modal-batch .id-column {
  width: 160px;
}

.stations-modal-batch .name-column {
  width: 250px;
}

.stations-modal-batch .payment-column {
  width: 250px;
}

.stations-modal-batch .idle-fee-column {
  width: 200px;
}

.stations-modal-batch .activation-fee-column {
  width: 200px;
}

.modal-batch {
  width: 1100px;
  height: auto;
  padding: 20px;
  background-color: white;
  }

  .modal-batch-header { 
    display: flex;
    font-family: 'Roboto', sans-serif;
    padding-bottom: 20px;
    font-size: 12px;
    color: #FC385C;
    font-weight: bold;
  }

  .updated-price {
    display: flex;
    font-family: 'Roboto', sans-serif;
    padding-top: 20px;
    font-size: 12px;
    color: #FC385C;
    font-weight: bold;
    padding-bottom: 30px;
  } 

  .table-head {
    display: flex;
    justify-content: center;
  }

  .id-station {
    display: inline-block;
    background-color: #FAF9F9;
    width: 160px;
  }
  
  .name-station {
    display: inline-block;
    background-color: #FAF9F9; 
    width: 250px;
  }
  
  .payment-station { 
    display: inline-block;
    background-color: #FAF9F9; 
    width: 250px;
  }

  .idle-fee-station {
    display: inline-block;
    background-color: #FAF9F9; 
    width: 200px;
  }

  .activation-fee-station {
    display: inline-block;
    background-color: #FAF9F9; 
    width: 200px;
  }

  .charge-model { 
    padding: 10px;
    background-color: #FAF9F9;
    width: 250px;
  }

  .updated-price-content {
    display: flex;
    gap: 24px;
  }

  .update-confirm { 
    display: flex;
    justify-content: baseline;
    padding: 16px;
    background-color: #FFF9DF;
    margin-bottom: 24px;
    border: 1px solid #FCD429;    
    font-size: 16px;
    gap: 8px;
  }

  .warning-modal { 
    display: flex;
    justify-content: baseline;
    padding: 8px;
    background-color: #FFF9DF;
    margin-top: 24px;
    border: 1px solid #FCD429;    
    font-size: 15px;
  }

  .confirm-update-modal {
    display: flex;
  }

  .modal-batch-footer { 
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    color: red;
  }

