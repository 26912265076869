.containerFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.containerTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 6px;
  margin-top: 15px;
}

.iconTitle {
  color: #faad14;
  font-size: 20px;
  margin-left: 8px;
  margin-right: 10px;
}

.textTitle {
  font-size: 16px;
  font-weight: bold;
  color: #000000;
}

.textInfo {
  font-size: 15px;
}
