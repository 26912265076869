// from https://codepen.io/havardob/pen/dyGGGzq
// THE LOADING EFFECT
.skeleton {
	background-color: #e2e5e7;
	// The shine that's going to move across the skeleton:
	background-image:			
			linear-gradient(
				90deg, 
				rgba(#fff, 0), 
				rgba(#fff, 0.5),
				rgba(#fff, 0)
			);
	background-size: 40px 100%; // width of the shine
	background-repeat: no-repeat; // No need to repeat the shine effect
	background-position: left -40px top 0; // Place shine on the left side, with offset on the left based on the width of the shine - see background-size
	&:not(.still) {
		animation: shine 1s ease infinite; // increase animation time to see effect in 'slow-mo'
	}
}

@keyframes shine {
	to {
		// Move shine from left to right, with offset on the right based on the width of the shine - see background-size
		background-position: right -40px top 0;
	}
}

// from https://www.codegrepper.com/code-examples/css/react+prevent+text+selection
.noselect {
  -webkit-touch-callout: none; // iOS Safari
  -webkit-user-select: none; // Safari
  -khtml-user-select: none; // Konqueror HTML
  -moz-user-select: none; // Old versions of Firefox
  -ms-user-select: none; // Internet Explorer/Edge
  user-select: none; // Non-prefixed version, currently supported by Chrome, Opera and Firefox
}

.ant-card-extra > :not(:last-child), td.actions-column > :not(:last-child) {
	margin-right: 8px;
}

.stationIDText {
  font-size: 12px;
}

.copyText, .stationIDText {
  cursor: pointer;
  color: #595959;
}

.stationIDText:hover, .copyText:hover {
  filter: brightness(133.33%);
}

/* .ant-table-tbody > tr.ant-table-row-selected td {
  background-color: red !important;
} */

/*
  antd breakpoints
  xs: 480px
  sm: 576px
  md: 768px
  lg: 992px
  xl: 1200px
  xxl: 1600px
*/
