.loadingSticker {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 9999;
  top: 0;
  background-color: #fff;
  opacity: 0.8;
}
