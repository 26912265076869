.blockColor {
  height: 26px;
  width: 6px;
}

.blockColor:hover {
  transform: scale(1.25);
  filter: brightness(90%) saturate(160%);
}

.times {
  display: flex;
  flex-direction: row;
}

.times > .time {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.times > .time:not(:last-child) {
  margin-right: 12px;
}

.contentPopover {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.squareColor {
  height: 10px;
  width: 10px;
  margin-right: 4px;
  position: relative;
  bottom: 1px;
}

.blockColor.white, .squareColor.white {
  background-Color: #FFF;
  border: 1px solid #CECECD;
}

.blockColor.green, .squareColor.green {
  background-color: #6FCF8A;
}

.blockColor.yellow, .squareColor.yellow {
  background-color: #FCD429;
}

.blockColor.red, .squareColor.red {
  background-color: #FF8585;
}

.blockColor.gray, .squareColor.gray {
  background-color: #A9A9A9;
}

.blockColor.purple, .squareColor.purple {
  background-color: #CE93D8;
}
