.container {
  padding: 0px 15px;
}
.header{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filter {
  border-radius: 5px;
  background-color: white;
  padding: 16px;
  margin-bottom: 15px;
  margin-top: 15px;
  box-shadow: 0px 1px 2px -1px;
  -moz-box-shadow: 0px 1px 2px -1px;
  -webkit-box-shadow: 0px 1px 2px -1px;
}

.table {
  background-color: white;
  padding: 0px 5px;
}

.footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.firstInput {
  border-radius: 4px 0px 0px 4px !important;
}

.buttonSearch {
  width: 10%;
  border-radius: 0px !important;
}


.success {
  color: #37841d !important;
  border-color: #37841d !important;
}

.spaceBetweenIcons {
  margin-left: 5px;
}

#form_edit_station .ant-form-item-label {
  font-weight: bold !important;
}

.box-payment-description {
  border: 1px solid #1890FF;
  background-color: #E6F7FF;
  border-radius: 2px;
  flex-direction: row;
  display: flex;
  align-items: center;
  padding: 4px;
}

.box-icon-info {
  border-radius: 50px;
  background-color: #1890FF;
  display: inline-flex;
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

.icon-info {
  color: #fff;
  font-size: 18px;
  width: 18px;
  height: 18px;
}

.payment-period {
  font-size: 14px;
  font-weight: bold;
  color: rgba(0,0,0,0.85);
}

.div-payment-description {
  flex-direction: row;
  display: flex;
  margin-top: 6,
}

.conected-stations-action-button {
  height: 28px;
  width: 140px;
  margin-bottom: 4px;
  justify-content: "center";
  align-items: "center";
}