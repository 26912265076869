.modalExpireProposal {
  max-width: 400px;
  min-width: 350px;
}

.modalExpireProposalTitle {
  display: flex;
  align-items: center;
  color: #fd5876;
  font-weight: 700;
  font-size: 14px;
}

.modalExpireProposalCard {
  background: #f3f2f3;
  border-radius: 6px;
  margin-bottom: 14px;
  padding: 7px;
}

.modalExpireProposalCardInfo {
  display: flex;
  align-items: center;
}

.modalExpireProposalCardInfo > span {
  color: #747277;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.modalExpireProposalCardIcon {
  color: #1890ff;
  font-size: 20px;
  margin-right: 8px;
}

.modalExpireProposalFooter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}