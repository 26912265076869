.proposal-form.container {
	padding: 15px;
}

.proposal-form h1 {
	text-align: left;
	margin-bottom: 20px;
	font-size: 16px;
	font-weight: 600;
	color: #FC385C;
	text-transform: uppercase;
}

.proposal-form h2 {
	text-align: center;
	margin-bottom: 20px;
	font-size: 24px;
	color: #333;
}

.proposal-form p {
	margin-bottom: 20px;
	font-size: 24px;
	color: #333;
}

.proposal-form .ant-divider-horizontal {
	margin-top: 40px;
	margin-bottom: 15px;
}

.proposal-form .page-wrapper {
	background-color: white;
	margin-top: 15px;
	padding: 30px 15px 15px 15px;
}

.proposal-form .form-wrapper {
	margin-top: -30px;
}

.proposal-form .title-wrapper {
	vertical-align: 6px;
	margin-left: 20px;
	font-size: 16px;
}

.proposal-form form label {
	display: block;
	font-weight: bold;
	color: #555;
}

.proposal-form form button {
	margin-left: 15px;
}

.proposal-form .hidden {
	opacity: 0;
	max-height: 0;
	overflow: hidden;
	transition: opacity 0.3s ease, max-height 0.3s ease;
}

.proposal-form .visible {
	opacity: 1;
	max-height: 1000px;
	transition: opacity 0.3s ease, max-height 0.3s ease;
}

.proposal-form .popup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
}

.proposal-form .popup-content {
	background-color: #fff;
	padding: 30px;
}

.readonly-proposal-input {
	background-color: #E7FFEE;
	color: #008724;
	border-width: 0;
	line-height: 32px;
	padding-left: 8px;
	text-overflow: ellipsis;
}

input[type="text"].readonly-proposal-input {
	width: calc(100% - 130px);
}

.proposal-form .ant-input-number {
	width: 100%;
}