.containerRadioGroup {
  max-height: 550px;
  width: 100%;
  background-color: rgb(255, 255, 255);
  overflow-y: scroll;
  margin-bottom: 20px;
}

.containerRadio {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
}

.containerSpin {
  height: 134px;
  display: flex;
  align-items: center;
  justify-content: center;
}
