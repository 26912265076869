.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 24px;
}

.textStatus {
  line-height: 18px;
}
