/* Container */
.container {
  width: 100%;
  border-radius: 4px;
  padding: 16px;
}

/* Header */
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
}
.infoHeader {
  display: flex;
  flex-direction: column;
}
.infoHeader:first-child {
  flex-grow: 1;
}
.infoHeader:not(:last-child) {
  margin-right: 32px;
}
.labelHeader {
  text-transform: uppercase;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  color: #747277;
}
.labelHeader.skeleton {
  height: 13.5px;
  border-radius: 4px;
}
.textHeader {
  font-style: normal;
  font-size: 18px;
  color: #434341;
}
.textHeader.skeleton {
  height: 22px;
  border-radius: 4px;
  margin-top: 8px;
}
.textHeader.skeleton.lowMargin {
  margin-top: 5px;
}
.textHeader.skeleton.noMargin {
  margin-top: 0px;
}
.textHeader:has(.textHeader.skeleton.noMargin) {
  margin-top: 8px;
}

/* Blocks */
.containerBlock {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
}
.containerBlock.skeleton {
  height: 26px;
  border-radius: 4px;
}
.containerBlock > :not(:last-child) {
  margin-right: 5px;
}

/* Timeline */
.containerTimeline {
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
}
.dividerTimeline {
  height: 0;
  border-top: 1px solid #cfcfcf;
  width: 100%;
  margin: 0 8px;
}
.textTimeline {
  text-transform: uppercase;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  color: #9d9d9d;
}

.disconnections {
  margin-top: 16px;
}
.disconnections-list > :not(:last-child) {
  margin-bottom: 3px;
}
.viewDisconnections {
  text-transform: lowercase;
  font-style: normal;
  font-weight: 500;
  font-size: 11.5px;
  color: #747277;
  text-decoration: underline;
}
.viewDisconnections:not(.skeleton) {
  cursor: pointer;
}
.viewDisconnections.skeleton {
  height: 13px;
  border-radius: 4px;
  margin-bottom: 4px;
}
