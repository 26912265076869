.margin-container-style {
  border: 1px solid #CECECD;
  padding: 15px;
  margin-bottom: 5px;
  margin-top: 30px;
  width: 50%;
}

.keep-btn-change-btn { 
  display: flex;
}

.container-contents {
  display: flex;
  justify-content: space-between;
  align-items: center;
}