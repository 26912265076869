:root {
  --default-bg: #ffffff;
  --default-text: #505050;
  --default-selected-bg: #1890ff;
  --default-selected-text: #ffffff;
  --default-link: #505050;
  --default-submenu-bg: #f0f0f0;
  --default-hover-color: #1890ff;
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: var(--default-hover-color);
}

.logo {
  height: 32px;
  margin: 16px;
}

.perfil {
  float: right;
  margin-right: 20px;
  display: inline;
}

.app-stage-link {
  font-style: italic;
}

.redirect-link {
  align-items: center;
  color: var(--whitelabel-submenu-text);
  display: flex;
  height: 40px;
  margin-left: 48px;
}
.redirect-link:hover {
  color: var(--whitelabel-selected-text);
}

.tag {
  display: flex;
  border: 1px solid var(--whitelabel-submenu-text);
  border-radius: 16px;
  margin-left: 5px;
  padding-inline: 4px;
  padding-top: 6px;
  padding-bottom: 6px;
  font-size: 10px;
}
.tag:hover {
  color: var(--whitelabel-selected-text);
}

.img-logo {
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}

/* TUPI */
.tupi {
  --whitelabel-bg: #ffffff;
  --whitelabel-text: #000000;
  --whitelabel-selected-bg: #f3f4f6;
  --whitelabel-selected-text: #5c00f0;
  --whitelabel-link: #000000a6;
  --whitelabel-submenu-bg: #ffffff;
  --whitelabel-submenu-text: #000000a6;
  --whitelabel-hover-color: #000000;
  --whitelabel-submenu-shadow: none;
}

/* NEO */
.neo {
  --whitelabel-bg: #565759;
  --whitelabel-text: #ffffff;
  --whitelabel-selected-bg: #1890ff;
  --whitelabel-selected-text: #ffffff;
  --whitelabel-link: #ffffffa6;
  --whitelabel-submenu-bg: #333334;
  --whitelabel-submenu-text: #ffffffa6;
  --whitelabel-hover-color: #ffffff;
  --whitelabel-submenu-shadow: 0 2px 8px rgba(0, 0, 0, 0.45) inset;
}

/* MOBILIZE */
.mobilize {
  --whitelabel-bg: #000000;
  --whitelabel-text: #ffffff;
  --whitelabel-selected-bg: #1890ff;
  --whitelabel-selected-text: #ffffff;
  --whitelabel-link: #ffffffa6;
  --whitelabel-submenu-bg: #2f2f2f;
  --whitelabel-submenu-text: #ffffffa6;
  --whitelabel-hover-color: #ffffff;
  --whitelabel-submenu-shadow: 0 2px 8px rgba(0, 0, 0, 0.45) inset;
}

/* SHELL */
.shell {
  --whitelabel-bg: #595959;
  --whitelabel-text: #ffffff;
  --whitelabel-selected-bg: #0097a9;
  --whitelabel-selected-text: #ffffff;
  --whitelabel-link: #ffffffa6;
  --whitelabel-submenu-bg: #7f7f7f;
  --whitelabel-submenu-text: #ffffffa6;
  --whitelabel-hover-color: #ffffff;
  --whitelabel-submenu-shadow: 0 2px 8px rgba(0, 0, 0, 0.45) inset;
}

/* BYD */
.byd {
  --whitelabel-bg: #686d71;
  --whitelabel-text: #ffffff;
  --whitelabel-selected-bg: #4f9edd;
  --whitelabel-selected-text: #ffffff;
  --whitelabel-link: #ffffffa6;
  --whitelabel-submenu-bg: #4e5356;
  --whitelabel-submenu-text: #ffffffa6;
  --whitelabel-hover-color: #ffffff;
  --whitelabel-submenu-shadow: 0 2px 8px rgba(0, 0, 0, 0.45) inset;
}

/* BRS */
.brs {
  --whitelabel-bg: #ffffff;
  --whitelabel-text: #505050;
  --whitelabel-selected-bg: #539c00;
  --whitelabel-selected-text: #ffffff;
  --whitelabel-link: #505050;
  --whitelabel-submenu-bg: #6fb51f;
  --whitelabel-submenu-text: #ffffffa6;
  --whitelabel-hover-color: #035731;
  --whitelabel-submenu-shadow: 0 2px 8px rgba(0, 0, 0, 0.45) inset;
}

/* VOLTZ */
.voltz {
  --whitelabel-bg: #ffffff;
  --whitelabel-text: #505050;
  --whitelabel-selected-bg: #143853;
  --whitelabel-selected-text: #ffffff;
  --whitelabel-link: #505050;
  --whitelabel-submenu-bg: #29597c;
  --whitelabel-submenu-text: #ffffffa6;
  --whitelabel-hover-color: #29597c;
  --whitelabel-submenu-shadow: 0 2px 8px rgba(0, 0, 0, 0.45) inset;
}

/* ION */
.ion {
  --whitelabel-bg: #ffffff;
  --whitelabel-text: #505050;
  --whitelabel-selected-bg: #a39e11;
  --whitelabel-selected-text: #ffffff;
  --whitelabel-link: #505050;
  --whitelabel-submenu-bg: #d0ca12;
  --whitelabel-submenu-text: #ffffffa6;
  --whitelabel-hover-color: #d0ca12;
  --whitelabel-submenu-shadow: 0 2px 8px rgba(0, 0, 0, 0.45) inset;
}

/* ANDRADE & SALES */
.andradesales {
  --whitelabel-bg: #ffffff;
  --whitelabel-text: #505050;
  --whitelabel-selected-bg: #cba102;
  --whitelabel-selected-text: #ffffff;
  --whitelabel-link: #505050;
  --whitelabel-submenu-bg: #ffcb05;
  --whitelabel-submenu-text: #ffffffa6;
  --whitelabel-hover-color: #ffcb05;
  --whitelabel-submenu-shadow: 0 2px 8px rgba(0, 0, 0, 0.45) inset;
}

/* CHARGERPRO */
.chargerpro {
  --whitelabel-bg: #ffffff;
  --whitelabel-text: #505050;
  --whitelabel-selected-bg: #18404a;
  --whitelabel-selected-text: #ffffff;
  --whitelabel-link: #505050;
  --whitelabel-submenu-bg: #1f5360;
  --whitelabel-submenu-text: #ffffffa6;
  --whitelabel-hover-color: #18404a;
  --whitelabel-submenu-shadow: 0 2px 8px rgba(0, 0, 0, 0.45) inset;
}

/* ELESOL */
.elesol {
  --whitelabel-bg: #ffffff;
  --whitelabel-text: #505050;
  --whitelabel-selected-bg: #66336e;
  --whitelabel-selected-text: #ffffff;
  --whitelabel-link: #505050;
  --whitelabel-submenu-bg: #a153a1;
  --whitelabel-submenu-text: #ffffffa6;
  --whitelabel-hover-color: #a153a1;
  --whitelabel-submenu-shadow: 0 2px 8px rgba(0, 0, 0, 0.45) inset;
}

/* PLUGBRA */
.plugbra {
  --whitelabel-bg: #ffffff;
  --whitelabel-text: #505050;
  --whitelabel-selected-bg: #035453;
  --whitelabel-selected-text: #ffffff;
  --whitelabel-link: #505050;
  --whitelabel-submenu-bg: #01c757;
  --whitelabel-submenu-text: #171105;
  --whitelabel-hover-color: #035453;
}

/* EVELETROPOSTOS */
.eveletroposto {
  --whitelabel-bg: #ffffff;
  --whitelabel-text: #505050;
  --whitelabel-selected-bg: #004da5;
  --whitelabel-selected-text: #ffffff;
  --whitelabel-link: #505050;
  --whitelabel-submenu-bg: #3cba4f;
  --whitelabel-submenu-text: #171105;
  --whitelabel-hover-color: #3cba4f;
  --whitelabel-submenu-shadow: 0 2px 8px rgba(0, 0, 0, 0.45) inset;
}

/* CIACHARGE */
.ciacharge {
  --whitelabel-bg: #ffffff;
  --whitelabel-text: #505050;
  --whitelabel-selected-bg: #a8ff03;
  --whitelabel-selected-text: #505050;
  --whitelabel-link: #505050;
  --whitelabel-submenu-bg: #f8f8f8;
  --whitelabel-submenu-text: #171105;
  --whitelabel-hover-color: #171105;
  --whitelabel-submenu-shadow: 0 2px 8px rgba(0, 0, 0, 0.45) inset;
}

/* LINKPLUSENERGY */
.linkplusenergy {
  --whitelabel-bg: #ffffff;
  --whitelabel-text: #505050;
  --whitelabel-selected-bg: #285a84;
  --whitelabel-selected-text: #ffffff;
  --whitelabel-link: #505050;
  --whitelabel-submenu-bg: #f8f8f8;
  --whitelabel-submenu-text: #171105;
  --whitelabel-hover-color: #171105;
  --whitelabel-menu-link-hover: #285a84;
  --whitelabel-submenu-shadow: 0 2px 8px rgba(0, 0, 0, 0.45) inset;
}

.ant-layout-sider {
  background: var(--whitelabel-bg);
}

.ant-menu-dark,
.ant-menu-dark > .ant-menu-submenu {
  background: var(--whitelabel-bg);
  color: var(--whitelabel-text);
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: var(--whitelabel-submenu-bg);
  -webkit-box-shadow: var(--whitelabel-submenu-shadow);
  box-shadow: var(--whitelabel-submenu-shadow);
}

.ant-menu.ant-menu-dark .ant-menu-item > a {
  color: var(--whitelabel-submenu-text);
}
.ant-menu.ant-menu-dark .ant-menu-item > a:hover {
  color: var(--whitelabel-menu-link-hover, var(--whitelabel-selected-text));
}

.ant-menu-dark .ant-menu-item-selected .anticon + span {
  color: var(--whitelabel-selected-text);
}

.ant-menu.ant-menu-dark .ant-menu-item-selected {
  background-color: var(--whitelabel-selected-bg);
}

.ant-menu-dark.ant-menu-inline > .ant-menu-item a {
  color: var(--whitelabel-link);
}

.ant-menu-dark .ant-menu-submenu-title:hover,
.ant-menu-dark.ant-menu-inline
  > .ant-menu-submenu.ant-menu-submenu-inline
  > .ant-menu-submenu-title:hover {
  color: var(--whitelabel-hover-color);
}

.ant-menu.ant-menu-dark .ant-menu-item-selected .anticon {
  color: var(--whitelabel-selected-text);
}

.ant-menu-inline-collapsed > .ant-menu-item .anticon {
  color: var(--whitelabel-text);
}

.ant-menu-inline-collapsed > .ant-menu-item:hover .anticon {
  color: var(--whitelabel-hover-color);
}

.ant-menu.ant-menu-dark .ant-menu-item .ant-menu-item-selected a {
  color: var(--whitelabel-selected-text);
}

.ant-menu-dark.ant-menu-inline > .ant-menu-item.ant-menu-item-active > a:hover {
  color: var(--whitelabel-hover-color);
}

.ant-menu-dark.ant-menu-inline
  > .ant-menu-submenu.ant-menu-submenu-inline
  > .ant-menu-submenu-title
  > .ant-menu-submenu-arrow::before,
.ant-menu-dark.ant-menu-inline
  > .ant-menu-submenu.ant-menu-submenu-inline
  > .ant-menu-submenu-title
  > .ant-menu-submenu-arrow::after {
  background: var(--whitelabel-text);
}

.ant-menu-dark.ant-menu-inline
  > .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open
  > .ant-menu-submenu-title
  > .ant-menu-submenu-arrow::before,
.ant-menu-dark.ant-menu-inline
  > .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open
  > .ant-menu-submenu-title
  > .ant-menu-submenu-arrow::after {
  background: var(--whitelabel-hover-color);
}
