.dot {
  height: 9px;
  width: 9px;
  border-radius: 50%;
  margin-right: 8px;
}

.dotUnavailable {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 9px;
  width: 9px;
  border-radius: 50%;
  margin-right: 8px;
  border: 1.5px solid;
}
.lineDotUnavailable {
  height: 6px;
  width: 1.5px;
  transform: rotate(45deg);
}
