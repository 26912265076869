.container {
  padding: 0px 15px;
}

.filter {
  border-radius: 5px;
  background-color: white;
  padding: 16px;
  margin-bottom: 15px;
  margin-top: 15px;
  box-shadow: 0px 1px 2px -1px;
  -moz-box-shadow: 0px 1px 2px -1px;
  -webkit-box-shadow: 0px 1px 2px -1px;
}

.table {
  background-color: white;
  padding: 0px 5px;
}

.footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.firstInput {
  border-radius: 4px 0px 0px 4px !important;
  /* max-width: 300px; */
}

.buttonSearch {
  width: 10%;
  border-radius: 0px !important;
}

.buttonNew {
  width: 10%;
  border-radius: 0px 4px 4px 0px !important;
}

.success {
  color: #37841d !important;
  border-color: #37841d !important;
}

.spaceBetweenIcons {
  margin-left: 5px;
}

#form_edit_station .ant-form-item-label {
  font-weight: bold !important;
}

.stationName {
  font-size: 14px;
  display: flex;
  flex-direction: row;
}

.viewStatus {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.stationsDisplayed {
  font-weight: 700;
}

.available {
  height: 9px;
  width: 9px;
  border-radius: 50%;
  background-color: #029b2d;
  margin-right: 10px;
}

.charging {
  height: 9px;
  width: 9px;
  border-radius: 50%;
  background-color: #7a84bf;
  margin-right: 10px;
}

.faulted {
  height: 9px;
  width: 9px;
  border-radius: 50%;
  background-color: #434341;
  margin-right: 10px;
}

.suspendedEV {
  height: 9px;
  width: 9px;
  border-radius: 50px;
  background-color: #fc850d;
  margin-right: 10px;
}

.preparing {
  height: 9px;
  width: 9px;
  border-radius: 50%;
  background-color: #e3e5ef;
  margin-right: 10px;
}

.finishing {
  height: 9px;
  width: 9px;
  border-radius: 50%;
  background-color: #e3efe6;
  margin-right: 10px;
}

.unavailable {
  height: 9px;
  width: 9px;
  border-radius: 50%;
  background-color: #f40000;
  margin-right: 10px;
}

.disconnected {
  height: 9px;
  width: 9px;
  border: 1px solid #434341;
  border-radius: 50%;
  background-color: #fff;
  margin-right: 10px;
}

.connectionTime {
  font-size: 14px;
}

.disconnectionTime {
  font-weight: bold;
  font-size: 12px;
  color: #f40000;
  margin-left: 21px;
}

.suspendedTime {
  font-weight: bold;
  font-size: 12px;
  color: #fc850d;
  margin-left: 21px;
}

.connectionDate {
  color: #595959;
  font-size: 12px;
  opacity: 0.65;
  font-weight: 400;
}

.buttonMenuReset {
  border: none;
}

.buttonMenuReset:hover {
  background: "#e6f7ff";
}
