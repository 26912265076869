.container {
  padding: 0px 15px;
}

.filter {
  border-radius: 5px;
  background-color: white;
  padding: 16px;
  margin-bottom: 15px;
  margin-top: 15px;
  box-shadow: 0px 1px 2px -1px;
  -moz-box-shadow: 0px 1px 2px -1px;
  -webkit-box-shadow: 0px 1px 2px -1px;
}

.table {
  background-color: white;
  padding: 0px 5px;
}

.footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.firstInput {
  border-radius: 4px 0px 0px 4px !important;
}

.buttonSearch {
  width: 10%;
  border-radius: 0px !important;
}

.buttonNew {
  width: 10%;
  border-radius: 0px 4px 4px 0px !important;
}

.success {
  color: #37841d !important;
  border-color: #37841d !important;
}

.spaceBetweenIcons {
  margin-left: 5px;
}

.modalNewCampaign {
  max-width: 625px;
  min-width: 425px;
}

.modalNewCampaignTitle {
  color: #fd5876;
  font-weight: 700;
}

.modalNewCampaignBody {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 16px;
  margin-bottom: 16px;
}

.cardModalNewCampaign {
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #cececd;
  width: 48%;
  padding: 25px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.titleCardModalNewCampaign {
  color: #434341;
  font-size: 16px;
  font-weight: 700;
}

.textAlignCenter {
  text-align: center;
}

.descriptionCardModalNewCampaign {
  text-align: center;
  margin-top: 20px;
}

.btnCardModalNewCampaign {
  width: 100%;
  margin-top: 20px;
}

.excelContainer {
  display: flex;
  align-items: center;
  width: 17rem;
}
