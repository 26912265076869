.usesContainer{
	display: flex;
	flex-direction: column;
  max-width: 230px;
}

.textContainer{
	display: flex;
	flex-direction: column;
}

.infoContainer{
	display: flex;
	flex-direction: row;
	justify-content: space-between
}

.zeroValue {
  color: #1890FF;
	font-weight: 600;
  padding-bottom: 20px;
}

.usedValue{
	color: #1890FF;
	font-weight: 600;
}

.usedValueQuota{
	color: #1890FF;
	font-weight: 600;
	margin-top: 22px;
}

.restValue{
	font-weight: 400;
	text-align: end;
}

.dateContainer{
	display: flex;
  flex-direction: column;

}
.dateFormatContainer {
  display: flex;
  flex-direction: row;
	justify-content: space-between
}

.formatContainer{
  display: flex;
  flex-direction: column;
}

.hourText{
	font-size:10px;
}

.hourExpireText{
	font-size:10px;
	text-align: end;
}

.expiredText {
	display: flex;
	justify-content: flex-end;
}

.progress{
	display: flex;
	flex-direction: row;
	justify-content: space-between
}

.dateTextContainer {
	display: flex;
	justify-content: flex-end;
}

.expiredText {
	margin-right: 3px;
}