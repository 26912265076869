.container {
  padding: 15px;
}

.containerEdit {
  padding: 0px 8px;
  background-color: #f0f2f5;
  border: 1px solid #dbdbdb;
  border-radius: 3px;
}

.success {
  color: green;
  border-color: green;
}
