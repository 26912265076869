.container {
  padding: 0px 15px;
}

.dashboard-wrapper {
  border-radius: 5px;
  background-color: white;
  padding: 16px;
  margin-bottom: 15px;
  margin-top: 15px;
  box-shadow: 0px 1px 2px -1px;
  -moz-box-shadow: 0px 1px 2px -1px;
  -webkit-box-shadow: 0px 1px 2px -1px;
}
