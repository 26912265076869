.dropdownActions {
  width: 24px;
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  padding: 0px 3px;
}

.dropdownActionsDot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #595959;
}
