.actions > .ant-layout-sider {
  background: #565559;
}

.actions > .custom-sidebar .ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  background: #565559;
}

.actions > .custom-sidebar .ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #333334;
}

.actions > .custom-sidebar .ant-menu-item.ant-menu-item-selected {
  background-color: #f3f2f3;
  display: flex;
  align-items: center;

  padding-right: 50px;
}
.actions > .custom-sidebar .ant-menu-item.ant-menu-item-selected span {
  color: #565559;
  overflow: hidden;
  text-overflow: ellipsis;
}
.actions > .custom-sidebar .ant-radio {
  margin-top: 4px;
}
.actions > .custom-sidebar .ant-form-item-label {
  font-weight: bold !important;
  color: #434341 !important;
  opacity: 0.85 !important;
}

.actions > .custom-sidebar .ant-menu-dark.ant-menu-inline>.ant-menu-submenu.ant-menu-submenu-inline>.ant-menu-submenu-title:hover {
  color: #fff;
}

.actions > .custom-sidebar .ant-menu-dark.ant-menu-inline>.ant-menu-item.ant-menu-item-active>a:hover {
  color: #fff;
}

.actions > .custom-sidebar .ant-menu-dark.ant-menu-inline>.ant-menu-item a {
  color: #ffffffa6;
}
