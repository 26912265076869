.filterContainer {
  border-radius: 5px;
  background-color: white;
  padding: 16px;
  margin-bottom: 15px;
  margin-top: 15px;
  box-shadow: 0px 1px 2px -1px;
  -moz-box-shadow: 0px 1px 2px -1px;
  -webkit-box-shadow: 0px 1px 2px -1px;
}

div.filterContainer div.ant-row.ant-form-item {
  margin-bottom: 5px !important;
}

.mainFilterContainer {
  display: flex;
  flex-direction: row;
}

.mainFilterInput {
  border-radius: 4px 0px 0px 4px !important;
}

.mainFilterBtnSearch {
  border-radius: 0px 4px 4px 0px !important;
}

.mainFilterBtnNew {
  width: 100%;
}
