.container {
  padding: 15px;
}
.availabilities-container > :not(:last-child) {
  margin-bottom: 8px;
}
.availabilities-container > :nth-last-child(2) {
  margin-bottom: 0px;
}

@media screen and (max-width: 1450px) {
  .cardContainerAvailability {
    width: 100%;
  }
}

@media screen and (min-width: 1451px) and (max-width: 1550px) {
  .cardContainerAvailability {
    width: 95%;
  }
}

@media screen and (min-width: 1551px) and (max-width: 1650px) {
  .cardContainerAvailability {
    width: 90%;
  }
}

@media screen and (min-width: 1651px) and (max-width: 1800px) {
  .cardContainerAvailability {
    width: 85%;
  }
}

@media screen and (min-width: 1801px) and (max-width: 2000px) {
  .cardContainerAvailability {
    width: min(1300px, 80%);
  }
}

@media screen and (min-width: 2001px) {
  .cardContainerAvailability {
    width: min(1500px, 75%);
  }
}

.cardContainerAvailability {
  display: flex;
  flex-direction: column;
}
.blockcontainer {
  width: 100%;
  border-radius: 4px;
  padding: 16px;
  /* margin-bottom: 8px; */
  border: 1px solid #d3d3d3;
  /* border: 1px solid #F3F2F3; */
}

#form_availability_stations .ant-col.ant-form-item-label {
  line-height: unset;
}

.textLastUpdate {
  font-weight: 400;
  font-size: 14px;
  color: #747277;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  align-self: center;
}

.containerInfoTab {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.containerPeriod {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.textPeriod {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #747277;
}
.textPeriod.skeleton {
  height: 16px;
  border-radius: 4px;
}

.textCritical {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #f40000;
}

.cardContainerAvailability > .ant-tabs-card > .ant-tabs-content {
  /* height: 120px; */
  margin-top: -17px;
  border: 1px solid #cececd;
  border-top-color: transparent;
  border-radius: 0 0 4px 4px;
}

.cardContainerAvailability
  > .ant-tabs-card
  > .ant-tabs-content
  > .ant-tabs-tabpane {
  background: #fff;
  padding: 16px;
  border-radius: 0 0 4px 4px;
}

.cardContainerAvailability > .ant-tabs-card > .ant-tabs-bar {
  border-color: #cececd;
}

.cardContainerAvailability .ant-tabs-nav {
  width: 100%;
}
.cardContainerAvailability > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  border-color: #cececd;
  background: #f3f2f3;
  padding: 12px 0px;
  height: auto !important;
  font-size: 14px;
  font-weight: 700;
  color: #747277;
  width: 25%; /* 100% dividido pela quantidade de tabs */
  text-align: center;
  /* border-radius: 0px; */
}

.cardContainerAvailability
  > .ant-tabs-card
  > .ant-tabs-bar
  .ant-tabs-tab-active {
  border: 1px solid #cececd;
  border-bottom-color: transparent;
  background: #fff;
  color: #434341;
}

.cardContainerAvailability
  > .ant-tabs.ant-tabs-card
  .ant-tabs-card-bar
  .ant-tabs-nav-container {
  height: auto !important;
}

.system-availability.skeleton {
  height: 20px;
  border-radius: 4px;
}

.system-availability-2.skeleton {
  height: 16px;
  border-radius: 4px;
}

.filter{
  border-radius: 5px;
  background-color:  white;
  padding: 16px;
  margin-bottom: 15px;
  margin-top: 15px;
  box-shadow: 0px 1px 2px -1px;
 -moz-box-shadow: 0px 1px 2px -1px;
 -webkit-box-shadow: 0px 1px 2px -1px;
}
