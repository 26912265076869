
.proposals-list-page .container {
	padding: 0px 15px;
}

.proposals-list-page h1 {
	text-align: center;
	margin-bottom: 20px;
	font-size: 24px;
	color: #333;
}

.proposals-list-page h2 {
	text-align: center;
	margin-bottom: 20px;
	font-size: 24px;
	color: #333;
}

.proposals-list-page p {
	text-align: center;
	margin-bottom: 20px;
	font-size: 24px;
	color: #333;
}

.proposals-list-page table {
	width: 100%;
	border-collapse: collapse;
}

.proposals-list-page th, td {
	padding: 10px;
	text-align: left;
	color: #747277;
}

.proposals-list-page .ant-table-thead > tr > th {
	color: #595959;
	font-weight: 600;
}

.proposals-list-page th {
	background-color: #f2f2f2;
}

.proposals-list-page .filter {
	border-radius: 0;
	box-shadow: none;
}

.proposals-list-page .title-wrapper {
	width: 50%;
	display: flex;
	justify-content: flex-start;
}

.proposals-list-page .new-proposal-wrapper {
	width: 50%;
	display: flex;
	justify-content: flex-end;
}

.proposals-list-actions-dropdown-menu .ant-menu {
	background-color: #fafafa;
	border-style: solid;
	border-width: thin;
	border-color: #e8e8e8;
	border-radius: 4px;
}

.proposals-list-actions-dropdown-menu .ant-btn {
	width: 112px;
	text-align: left;
}
