.campaignResume {
  width: 100%;
  padding: 16px;
  background-color: #fff;
  border-radius: 4px;
  margin: 14px 0px;
}

.campaignResumeTitle {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #434341;
}

.addCouponButton {
  color: #1890ff;
  cursor: pointer;
  font-weight: 600;
  font-size: 15px;
  text-decoration-line: underline;
}
.couponsFilterAndCsv {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.couponsFilterText {
  color: #fd5876;
  font-weight: bold;
  font-size: 16px;
  margin-top: 16px;
}

.couponsFilterInput {
  margin-top: 10px;
  max-width: 300px;
}

.modalRemoveUser {
  max-width: 400px;
  min-width: 350px;
}

.modalRemoveUserTitle {
  display: flex;
  align-items: center;
  color: #fd5876;
  font-weight: 700;
  font-size: 12px;
}

.modalRemoveUserCard {
  background: #f3f2f3;
  border-radius: 6px;
  margin-bottom: 14px;
  padding: 7px;
}

.modalRemoveUserCardInfo {
  display: flex;
  align-items: center;
}

.modalRemoveUserCardInfo > span {
  color: #747277;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.modalRemoveUserCardIcon {
  color: #1890ff;
  font-size: 20px;
  margin-right: 8px;
}

.modalRemoveUserFooter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}

.addCouponLabel {
  color: #fd5876;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 20px;
}

.addCouponModalFooter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  gap: 20px;
}

.modalCouponTitle {
  display: flex;
  align-items: center;
  color: #fd5876;
  font-weight: 700;
  font-size: 12px;
}

.modalCouponUserCard {
  background: #f3f2f3;
  border-radius: 6px;
  margin: 15px 0px;
  padding: 7px;
}

.modalCouponUserCardInfo {
  display: flex;
  align-items: center;
} */

 .modalCouponUserCardInfo > span {
  color: #747277;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.modalCouponUserCardIcon {
  color: #1890ff;
  font-size: 20px;
  margin-right: 8px;
}

.modalCouponUserFooter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}
