.containerQrCode {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.textSwitch {
  margin-left: 8px;
  font-size: 17px;
  font-weight: bold;
}

.qrcode_capture {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 424px;
  height: 424px;
  padding: 12px;
}

.my-skeleton {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #e0e0e0 25%, #f0f0f0 50%, #e0e0e0 75%);
  background-size: 200% 100%;
  animation: my-skeleton-loading 1.5s infinite linear;
  border-radius: 8px;
}

@keyframes my-skeleton-loading {
  0% {
      background-position: 200% 0;
  }
  100% {
      background-position: -200% 0;
  }
}
